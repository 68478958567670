.Container {
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  /* margin-top: 1em; */
}

.Title {
  margin-top: 0;
  text-align: center;
  font-size: 25;
  /* background-color: #555555; */
}

.Jacky {
  
}

.instagram {
  text-align: center;
}

.menuItems {
  text-align: right;
  /* background-color: #555555; */
}

.Header {
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0;
}

.background {
  /* background-color: #555555; */
  height: 100%;
}

.body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvas {
}

.Main {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
}

/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
*/